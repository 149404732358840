module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"publicApiKey":"NzRkNjMyMDAtN2YyOC00Nzg4LTlmZmEtMmU5ZTBlMmQ3YTU0NjM4MjMyMDE5NTY5NzA2ODY0","defaultLang":"es","autopop":true,"currency":"eur","loadStrategy":"on-user-interaction"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
