exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-category-js": () => import("./../../../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-content-page-js": () => import("./../../../src/pages/content-page.js" /* webpackChunkName: "component---src-pages-content-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-info-js": () => import("./../../../src/pages/my-info.js" /* webpackChunkName: "component---src-pages-my-info-js" */),
  "component---src-pages-order-details-js": () => import("./../../../src/pages/order-details.js" /* webpackChunkName: "component---src-pages-order-details-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-review-products-js": () => import("./../../../src/pages/review-products.js" /* webpackChunkName: "component---src-pages-review-products-js" */)
}

